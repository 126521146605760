exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-archives-tsx": () => import("./../../../src/pages/archives.tsx" /* webpackChunkName: "component---src-pages-archives-tsx" */),
  "component---src-pages-digital-painting-tsx": () => import("./../../../src/pages/digital-painting.tsx" /* webpackChunkName: "component---src-pages-digital-painting-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pencil-tsx": () => import("./../../../src/pages/pencil.tsx" /* webpackChunkName: "component---src-pages-pencil-tsx" */),
  "component---src-pages-pictures-index-tsx": () => import("./../../../src/pages/pictures/index.tsx" /* webpackChunkName: "component---src-pages-pictures-index-tsx" */),
  "component---src-pages-pictures-mdx-slug-tsx": () => import("./../../../src/pages/pictures/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-pictures-mdx-slug-tsx" */)
}

